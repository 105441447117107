import * as React from "react";
import Layout from "../components/layout";
import BkkIFComp from "../components/bkkIFComp";

const BkkIF = () => {
  return(
    <Layout>
      <BkkIFComp />
    </Layout>
  );
};

export default BkkIF